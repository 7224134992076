import * as React from "react"
import GradientJumbo from "../../components/gradient-jumbo"
import Layout from "../../components/layout"
import Contact from "../../components/sections/contact"
import ProjectCards from "../../components/sections/portifolio-e-iniciativas/project_cards"
import Seo from "../../components/seo"

const PortfolioPage = props => (
  <Layout language="en" pathname={props.location.pathname}>
    <Seo
      title="Portfolio & Initiatives"
      lang="en"
      description="Keep track of the CAMARADES' Systematic reviews and meta-analyses by Brazilian scientists"
    />
    <GradientJumbo
      subtitle="Portfolio & Initiatives"
      title="Systematic reviews and meta-analyses by Brazilian scientists"
    />
    <div id="plus"></div>
    <ProjectCards language="en" />
    <Contact language="en" />
  </Layout>
)

export default PortfolioPage
